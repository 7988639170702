import * as React from 'react'
import { Component } from 'react';
import { Modal, DatePicker, Input, Form, Spin } from 'antd-min';
import { DateHelper } from '@app/util/helper';
import { SchoolLocale, GSAdminLocale } from '@app/locales/localeid';
import { GLGlobal, GLForm, FormItemsLayout, FormHelper, alignPop, LanguageDateFormat, GLFormProps, ComparisonOperator } from 'gl-commonui';
import * as moment from "moment";
import { rangeDateValider, lazyInject, TYPES, extendForm, fmtMsg, textareaValider } from '@app/util';
import { IVisitationService } from '@app/service/coach/visitation';
import { trimPlainText } from '@app/util/coach/fun';

interface GenerateAgainProps extends GLFormProps {
    visitation: any
    callback?: () => void
}

interface GenerateAgainStates { 
    visible: boolean
    spinning: boolean
}
@GLForm.create()
export class GenerateAgain extends Component<GenerateAgainProps, GenerateAgainStates> {
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService
    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            spinning: false,
        }
    }
    componentWillReceiveProps(nextProps: any, nextContext: any) {
        !this.state.visible && this.setState({ visible: true });
    };
    handleCancel() {
        const { form } = this.props;
        form && form.resetFields();
        this.setState({  visible: false });
    }
    disableDates(current) {
        let disableDate = moment().add(-1, 'day').toDate();
        return current < disableDate;
    }
    onGenerateAgain(e) {
        e.preventDefault();
        const { visitation: { id }, form, callback } = this.props;
        form.validateFields((err, values) => {
            if (!err) {
                values.startDate = moment.utc(values.startDate);
                values.endDate = moment.utc(values.endDate);
                values.expireDate = moment.utc(values.expireDate);
                values.note = trimPlainText(values.note);
                const params = {
                    visitationId: id,
                    ...values, 
                }
                this.setState({ spinning: true });
                this.visitationservice.reGeneratingScheduleDate(params).then(() => {
                    callback && callback();
                    form && form.resetFields();
                    this.setState({  visible: false, spinning: false });
                });
            }
        });
    }
    formatDate(date) {
        return DateHelper.formatDate2Local(date);
    }
    onCancel() {
        this.setState({ visible: false });
    }
    lengthValidator(localeId, rigthVal) {
        return [FormHelper.ruleForCompareLength(localeId, ComparisonOperator.LessOrEqualsThan, rigthVal)];
    }
    noteValidator(form) {
        return textareaValider(form, "note").concat(this.lengthValidator(SchoolLocale.VisitationNoteText, 500));
    }
    render() {
        const { visible, spinning } = this.state;
        const { form, visitation: { campusName, note, associatedInfo: { visitationAlternateDate } } } = this.props;
        const renderFormItem = FormHelper.renderFormItem;
        const dateValider = (msg) => {
            return rangeDateValider(form, "startDate", "endDate", msg);
        }
        const expiryDateValider = (msg) => {
            return rangeDateValider(form, "expireDate", "startDate", msg);
        };
        return <div className="generate-again">
            <Modal
                width="580px"
                wrapClassName="visitation-modal vertical-center-modal"
                title={campusName}
                visible={visible}
                onOk={(e) => this.onGenerateAgain(e)}
                onCancel={() => this.handleCancel()}
            >
                <Spin spinning={spinning}>
                <GLForm form={form}>
                    <FormItemsLayout colTotal={2}>
                        {renderFormItem(
                            form,
                            SchoolLocale.ClassNewStartDate,
                            "startDate",
                            <DatePicker
                                {...alignPop({ type: "DatePicker" })}
                                format={
                                    LanguageDateFormat[GLGlobal.intl.locale]
                                }
                                disabledDate={this.disableDates.bind(this)}
                            />,
                            visitationAlternateDate ? DateHelper.toLocalMoment(visitationAlternateDate.startDate) : null,
                            true,
                            dateValider(
                                fmtMsg({ id: GSAdminLocale.NotificationCreateEarlierDate })
                            )
                        )}
                        {renderFormItem(
                            form,
                            SchoolLocale.ClassNewEndDate,
                            "endDate",
                            <DatePicker
                                {...alignPop({ type: "DatePicker" })}
                                format={
                                    LanguageDateFormat[GLGlobal.intl.locale]
                                }
                                disabledDate={this.disableDates.bind(this)}
                            />,
                            visitationAlternateDate ? DateHelper.toLocalMoment(visitationAlternateDate.endDate) : null,
                            true,
                            dateValider(
                                fmtMsg({ id: GSAdminLocale.NotificationCreateLaterDate })
                            )
                        )}
                    </FormItemsLayout>
                    <div className="expiry-date">
                        <FormItemsLayout colTotal={2}>
                            {renderFormItem(
                                form,
                                SchoolLocale.VisitationRequestExpiryDate,
                                "expireDate",
                                <DatePicker
                                    {...alignPop({ type: "DatePicker" })}
                                    format={LanguageDateFormat[GLGlobal.intl.locale]}
                                    disabledDate={this.disableDates.bind(this)}
                                />,
                                null,
                                true,
                                expiryDateValider(fmtMsg({ id: SchoolLocale.UpcomingEventExpiryEarlierStart }))
                            )}
                        </FormItemsLayout>
                    </div>
                    <Form.Item className="reason">
                        {renderFormItem(extendForm(form), SchoolLocale.VisitationNoteText, "note", 
                                <Input.TextArea rows={4} />, note, true, this.noteValidator(form))}
                    </Form.Item>
                </GLForm>
                </Spin>
            </Modal>
        </div>
    }
}