import React, {Component} from "react";
import {Card, Spin, Select} from "antd-min";
import {MainTitle} from "@app/components/school/school-page-title";
import "../dashboard/index.less";
import {DateHelper, fmtMsg, lazyInject, TYPES} from "@app/util";
import {SchoolLocale} from "@app/locales/localeid";
import {connect, GLFormProps, GLUtil, MessageHelper, NotificationType, withRouter} from "gl-commonui";
import {Icon} from "antd";
import {ColumnLink, WijmoGrid} from "@app/components";
import {Link, RouteComponentProps} from "react-router-dom";
import {PathConfig, SchoolPathConfig} from "@app/config/pathconfig";
import {IVisitationService, SchoolVisitationStatus} from "@app/service/coach/visitation";
import {VisitationChannelType, VisitationCoachStage, VisitationType} from "@app/util/coach/enum";
import {AccessibleSchool, ISchoolService} from "@app/service/schools";
import {IAccountService} from "@app/service/admin/accountservice";
import {orderBy} from "lodash";
import {GenerateAgain} from "../component/generate-again";
import {CollectionView} from "wijmo/wijmo";
import {setReloadCoachData} from '@app/states/dashboard';
import {getDiffDays} from "@app/util/coach/fun";

const { Grid, Column } = WijmoGrid;
interface CoachDashboardProps {
    hideTitle?: boolean;
    forceRefresh?: boolean
    setReloadCoachData?: (d) => void
}
interface CoachDashboardStates {
    spinning?: boolean;
    schoolstatuses: SchoolVisitationStatus[];
    channels?: any[];
    accessibleSchools: AccessibleSchool[];
    selectedSchoolId: null | string;
}

@connect(null, {setReloadCoachData})
export class CoachDashboard extends Component<CoachDashboardProps, CoachDashboardStates> {
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService;
    @lazyInject(TYPES.ISchoolService)
    schoolService: ISchoolService;
    constructor(props) {
        super(props);
        this.state = {
            spinning: false,
            schoolstatuses: [],
            channels: [],
            accessibleSchools: [],
            selectedSchoolId: null,
        };
    }
    componentWillMount() {
        this.getStatusesChannels();
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.forceRefresh != nextProps.forceRefresh &&
            nextProps.forceRefresh) {
                this.getStatusesChannels();
                nextProps.setReloadCoachData(false);
        }
    }
    getStatusesChannels() {
        const actions = [this.visitationservice.getSchoolStatuses(), this.visitationservice.getChannels(), this.schoolService.getAccessibleSchools4Coach()];
        this.setState({ spinning: true });
        Promise.all(actions).then(([schoolstatuses, channels, accessibleSchools]) => {
            this.setState({
                spinning: false,
                schoolstatuses,
                channels: channels,
                accessibleSchools,
                selectedSchoolId: schoolstatuses[0] && accessibleSchools.find((school: AccessibleSchool) => school.id === schoolstatuses[0].schoolId) ? schoolstatuses[0].schoolId : null
            });
        });
    }

    onSchoolSelectChange = async (schoolId: string, option: any) => {
        if (this.state.schoolstatuses.findIndex(school => school.schoolId === schoolId) > -1) {
            this.setState({selectedSchoolId: schoolId});
            return;
        }
        try {
            const keys = option.key.split('/');
            const regionId: string = keys[0];
            const schoolId: string = keys[1];
            const schoolName: string = option.props.children;

            this.setState({
                selectedSchoolId: schoolId,
                schoolstatuses: [
                    {
                        schoolId,
                        schoolName,
                        regionId,
                        dateSinceLastReview: null,
                        onSiteRemaining: null,
                        onSiteTotal: null,
                        lvaRemaining: null,
                        lvaTotal: null,
                        isCoachRelated: true
                    },
                    ...this.state.schoolstatuses
                ]
            })
            const selectedSchoolStatuses = await this.visitationservice.getSchoolStatusesById(schoolId);
            this.setState({
                schoolstatuses: [selectedSchoolStatuses, ...this.state.schoolstatuses.slice(1) ]
            })
        } catch (error) {
        }

    }


    isSchoolSelectShown = () => {
        const schoolIdsOnGrid = this.state.schoolstatuses.map(school => school.schoolId);
        const schoolIdsOnSelect = this.state.accessibleSchools.map(school => school.id);
        return !schoolIdsOnSelect.every(id => schoolIdsOnGrid.includes(id));
    }

    renderSchoolSelect = () => {
        if (!this.isSchoolSelectShown()) {
            return null;
        }
        return (
            <Select
                showSearch
                style={{ width: 350, marginBottom: 5 }}
                placeholder={fmtMsg({ id: SchoolLocale.CoachSchoolSelectPlaceholder })}
                value={this.state.selectedSchoolId}
                onChange={this.onSchoolSelectChange}
                filterOption={(input, option) => typeof option.props.children === 'string' &&  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
             >
                {this.state.accessibleSchools.map(({id, name, regionId}) => {
                    return <Select.Option key={`${regionId}/${id}`} value={id}>{name}</Select.Option>
                })}
            </Select>
            )
    }

    render() {
        const { schoolstatuses, channels, spinning } = this.state;
        return (
            <div className="coach-dashboard">
                {!this.props.hideTitle && <MainTitle plain={fmtMsg({ id: SchoolLocale.HomeCoachDashboard })} />}
                <Spin spinning={spinning}>
                    {this.renderSchoolSelect()}
                    <VisitationList schoolstatuses={schoolstatuses} callback={() => this.getStatusesChannels()}></VisitationList>
                    {!!channels.length && <OnGoingChannel channels={channels} callback={() => this.getStatusesChannels()}></OnGoingChannel>}
                </Spin>
            </div>
        );
    }
}

interface VisitationListProps extends GLFormProps, Partial<RouteComponentProps> {
    schoolstatuses: SchoolVisitationStatus[];
    callback: () => void;
}

interface VisitationListStates {
    recorder?: any;
    loading?: boolean;
    campuses?: any[];
    teachers?: any[];
}

@withRouter
class VisitationList extends Component<VisitationListProps, VisitationListStates> {
    @lazyInject(TYPES.IAccountService)
    accountservice: IAccountService;
    @lazyInject(TYPES.ISchoolService)
    schoolservice: ISchoolService;
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }
    setColumnTitle(type, text) {
        return (
            <span className="table-title">
                <span>{text}</span>
                {type == VisitationType.OnSite && <Icon type="environment" />}
                {type == VisitationType.LVA && <Icon type="video-camera" />}
            </span>
        );
    }
    formatData(items) {
        return items.map((item) => ({
            ...item,
            daysSinceLastReview: item.dateSinceLastReview ? Math.abs(getDiffDays(item.dateSinceLastReview)) : null,
        }));
    }
    render() {
        return (
            <div>
                <Grid className="visitationlist" itemsSource={new CollectionView(this.formatData(this.props.schoolstatuses))} pagination={false}>
                    <Column
                        binding="schoolName"
                        header={fmtMsg({ id: SchoolLocale.CoachSchool })}
                        render={(value, item) => {
                            const url = GLUtil.pathStringify(PathConfig.Schools, { regionId: item.regionId, schoolId: item.schoolId });
                            return (
                                <ColumnLink history={this.props.history} url={url}>
                                    <span className="school-name" title={value}>
                                        {value}
                                    </span>
                                </ColumnLink>
                            );
                        }}
                    />
                    <Column
                        binding="daysSinceLastReview"
                        header={fmtMsg({ id: SchoolLocale.CoachDaysSinceLastReview })}
                    />
                    <Column binding="onSiteTotal"
                        align="left"
                        renderHeader={() => this.setColumnTitle(0, fmtMsg({ id: SchoolLocale.CoachTotal }))} />
                    <Column binding="onSiteRemaining"
                        align="left"
                        renderHeader={() => this.setColumnTitle(0, fmtMsg({ id: SchoolLocale.CoachRemaining }))}
                        render={(value, item) => {
                            if (value == null) return;
                            return <span className={value != Math.abs(value) ? "red" : ""}>{value}</span>;
                        }} />
                    <Column binding="lvaTotal"
                        align="left"
                        renderHeader={() => this.setColumnTitle(1, fmtMsg({ id: SchoolLocale.CoachTotal }))} />
                    <Column binding="lvaRemaining"
                        align="left"
                        renderHeader={() => this.setColumnTitle(1, fmtMsg({ id: SchoolLocale.CoachRemaining }))}
                        render={(value, item) => {
                            if (value == null) return;
                            return <span className={value != Math.abs(value) ? "red" : ""}>{value}</span>;
                        }} />
                    <Column
                        allowSorting={false}
                        binding="isCoachRelated"
                        render={(value, item) => {
                            if (!value) {
                                return null;
                            }
                            const url = GLUtil.pathStringify(SchoolPathConfig.VisitationNew, { regionId: item.regionId, schoolId: item.schoolId });
                            return (
                                <ColumnLink history={this.props.history} url={url}>
                                        {fmtMsg({ id: SchoolLocale.VisitationShoolStatusNew })}
                                </ColumnLink>
                            );
                        }}
                    />
                </Grid>
            </div>
        );
    }
}

interface OnGoingChannelProps {
    channels: any[];
    callback: () => void;
}
interface OnGoingChannelStates {}

class OnGoingChannel extends Component<OnGoingChannelProps, OnGoingChannelStates> {
    constructor(props) {
        super(props);
    }
    render() {
        const { channels, callback } = this.props;
        return (
            <div className="ongoing-channel">
                <ChannelList key={0}
                    channels={channels}
                    callback={() => callback()}
                    type={VisitationChannelType.Preparation}
                    title={fmtMsg({ id: SchoolLocale.VisitationChannelPrep })}
                />
                <ChannelList key={1}
                    channels={channels}
                    type={VisitationChannelType.Observation}
                    title={fmtMsg({ id: SchoolLocale.VisitationChannelObservation })}
                />
                <ChannelList key={2}
                    channels={channels}
                    type={VisitationChannelType.Review}
                    title={fmtMsg({ id: SchoolLocale.VisitationChannelReview })}
                />
            </div>
        );
    }
}

interface ChannelListProps {
    type: number;
    title: string;
    channels: any[];
    callback?: () => void;
}
interface ChannelListStates {
    sortedChannels?: any[];
}

class ChannelList extends Component<ChannelListProps, ChannelListStates> {
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService;
    @lazyInject(TYPES.ISchoolService)
    schoolService: ISchoolService;
    generateAgainModal;
    constructor(props) {
        super(props);
        this.state = {
            sortedChannels: []
        };
    }
    componentDidMount() {
        const { type, channels } = this.props;
        this.sortChannels(type, channels);
    }
    componentWillReceiveProps(nextProps) {
        this.generateAgainModal = null;
        this.sortChannels(nextProps.type, nextProps.channels);
    }
    formatStageDateDue(v) {
        const diffDays = getDiffDays(v.expireDate);
        const absDiffDays = Math.abs(diffDays);
        switch (true) {
            case diffDays == -1:
                return <span className="light">({fmtMsg({ id: SchoolLocale.VisitationDayOverdue})})</span>;
            case diffDays < -1:
                return <span className="light">({fmtMsg({ id: SchoolLocale.VisitationDaysOverdue}, { n: absDiffDays })})</span>;
            case diffDays == 0:
                return <span>({fmtMsg({ id: SchoolLocale.VisitationDueToday})})</span>;
            case diffDays == 1:
                return <span>({diffDays} {fmtMsg({ id: SchoolLocale.VisitationDayText})})</span>;
            case diffDays > 1:
                return <span>({diffDays} {fmtMsg({ id: SchoolLocale.VisitationDaysText})})</span>;
            default:
                return null;
        }
    }
    setDate(v) {
        const stageDateText = DateHelper.formatDate2Local(v.stageDate);
        const startDateText = DateHelper.formatDate2Local(v.startDate);
        switch (v.stage) {
            case VisitationCoachStage.ScheduleDate:
                return (
                    <span>
                        {fmtMsg({ id: SchoolLocale.CoachInvitationSent })}:<span className="date">{stageDateText}</span>
                        {this.formatStageDateDue(v)}
                    </span>
                );
            case VisitationCoachStage.PrevisitationForm:
                return (
                    <span>
                        {fmtMsg({ id: SchoolLocale.CoachDate })}:<span className="date">{startDateText}</span>
                    </span>
                );
            case VisitationCoachStage.VisitSchool:
            case VisitationCoachStage.VideoManager:
            case VisitationCoachStage.TeacherReview:
            case VisitationCoachStage.SchoolReview:
                return (
                    <span>
                        {fmtMsg({ id: SchoolLocale.CoachDate })}:<span className="date">{stageDateText}</span>
                    </span>
                );
        }
    }
    sendReminder(e, v) {
        e.preventDefault();
        const params = {
            visitationId: v.id,
        };
        this.visitationservice.sendScheduleDateReminder(params).then((data) => {
            MessageHelper.Message(NotificationType.Success, fmtMsg({ id: SchoolLocale.VisitationSendReminderTips }));
        });
    }
    generateAgain(e, v) {
        const { callback } = this.props;
        this.generateAgainModal = <GenerateAgain visitation={v} callback={() => callback && callback()} />;
        this.forceUpdate();
    }
    formatScheduleDateStageLink(v) {
        const diffDays = getDiffDays(v.expireDate);
        const generateAgainLink = <span onClick={(e) => this.generateAgain(e, v)}>{fmtMsg({ id: SchoolLocale.CoachGenerateAgain })}</span>;
        const sendReminderLink = <span onClick={(e) => this.sendReminder(e, v)}>{fmtMsg({ id: SchoolLocale.CoachSendReminder })}</span>;
        switch (true) {
            case diffDays < 0:
                return generateAgainLink;
            case diffDays >= 0:
                return sendReminderLink;
        }
    }

    generateDetailLink(v, localId: string, showReviewDueCount = false) {
        return (
            <Link
                key={"link_" + v.id}
                to={{
                    pathname: GLUtil.pathStringify(SchoolPathConfig.CoachVisitation, {
                        regionId: v.regionId,
                        schoolId: v.schoolId,
                        visitationId: v.id,
                    }),
                }}
            >
                {showReviewDueCount && <span>{`${v.reviewDueCount} `}</span>}
                {fmtMsg({ id: localId })}
            </Link>
        )
    }

    setLink(v) {
        switch (v.stage) {
            case VisitationCoachStage.ScheduleDate:
                return (
                    <>
                        {this.formatScheduleDateStageLink(v)}
                        <div style={{marginTop: "5px"}}>{this.generateDetailLink(v, SchoolLocale.CoachViewDetails)}</div>
                    </>
                );
            case VisitationCoachStage.PrevisitationForm:
            case VisitationCoachStage.VisitSchool:
            case VisitationCoachStage.VideoManager:
                return <>{this.generateDetailLink(v, SchoolLocale.CoachViewDetails)}</>;
            case VisitationCoachStage.TeacherReview:
            case VisitationCoachStage.SchoolReview:
                return <>{this.generateDetailLink(v, SchoolLocale.CoachReviewDue, true)}</>;
        }
    }
    sortChannels(type, channels) {
        const stageMap = new Map<number, number>([
            [VisitationCoachStage.ScheduleDate, VisitationChannelType.Preparation],
            [VisitationCoachStage.PrevisitationForm, VisitationChannelType.Preparation],
            [VisitationCoachStage.VisitSchool, VisitationChannelType.Observation],
            [VisitationCoachStage.VideoManager, VisitationChannelType.Observation],
            [VisitationCoachStage.TeacherReview, VisitationChannelType.Review],
            [VisitationCoachStage.SchoolReview, VisitationChannelType.Review],
        ]);
        const formatChannels = channels
            .filter((v) => type == stageMap.get(v.visitation.stage))
            .map((v) => {
                return { ...v.visitation, reviewDueCount: v.reviewDueCount };
            });
        const sortedChannels = orderBy(formatChannels, ["type", "stageDate"], ["asc", "desc"]);
        this.setState({ sortedChannels });
    }
    render() {
        const { type, title } = this.props;
        return (
            <div className="channel-list">
                <div className="channel-title">{title}</div>
                <div>
                    {this.state.sortedChannels.map((v, k) => {
                        return (
                            <div className="channel-item" key={k}>
                                <Card
                                    hoverable
                                    size="default"
                                    title={
                                        <>
                                            <div title={v.schoolName}>{v.schoolName}</div>
                                            {v.type == VisitationType.OnSite && <div title={v.campusName}>{v.campusName}</div>}
                                        </>
                                    }
                                    extra={
                                        <>
                                            {v.type == VisitationType.LVA ? <Icon type="video-camera" /> : <Icon type="environment" />}
                                        </>
                                    }
                                >
                                    <Card.Meta
                                        description={
                                            <div className="edit-list-card-body">
                                                <div>{this.setDate(v)}</div>
                                                <div className="action">{this.setLink(v)}</div>
                                            </div>
                                        }
                                    />
                                </Card>
                            </div>
                        );
                    })}
                </div>
                {this.generateAgainModal}
            </div>
        );
    }
}
